import React, { useEffect } from 'react'
import './pricing-page.styles.scss';
import UnauthLayout from '@/components/layout-unauth/unauth-layout.component';
import PricingTable from '@/components/SHARED/pricing-table/pricing-table.component';

const PricingPage = () => {

    useEffect(() => {
        var r = document.querySelector(':root');
        r.style.setProperty('--primary', '#674fa7');
        r.style.setProperty('--primary-trans', '#674fa7a6');
    }, [])

    return (
        <div className='pricing-page'>
            <UnauthLayout>
                <PricingTable />
            </UnauthLayout>
        </div>
    )
}

export default PricingPage