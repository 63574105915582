import React, { useEffect, useLayoutEffect } from 'react'
import './org-list-container.styles.scss'
import OrgList from './org-list.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Route, Routes, useNavigate } from 'react-router-dom'
import CreateEditOrg from './create-org/create-edit-org.component'

const OrgListContainer = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className='section-title with-icon'>
                <span>Manage Organizations</span>
                <FontAwesomeIcon className='icon' title="Create New Organization" icon={faPlusCircle} onClick={() => navigate('create')} />
            </div>
            <OrgList />
        </div>
    )
}

export default OrgListContainer