import { useEffect } from 'react'

import './App.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import LoadingPage from './pages/loading/loading-page.page'
import Register from './pages/sign-in/register.component';
import ProfilePage from './pages/profile/profile.page';
import SignIn from './pages/sign-in/sign-in.component';
import LogoutPage from './pages/logout/logout-page.page';
import PageNotFound from './pages/page-not-found/page-not-found.component';
import LandingPage from './pages/landing-page/landing-page.page';
import HomePage from './pages/home-page/home-page.page';
import ManageGamePage from './pages/manage-game/manage-game.page';
import GamesPage from './pages/games/games-page.page';
import JoinPage from './pages/join-page/join-page.page';
import AdminPage from './pages/admin/admin.page';
import PlayGamePage from './pages/play-game/play-game.page';
import ChangeLog from './pages/changelog/changelog.page';
import { screenSizes } from './utils/responsiveQueries';
import { setScreenSize } from './state/slices/contentSlice';
import LeaderboardPage from './pages/leaderboard/leaderboard.page';
import { getUserMembershipFromDb } from './utils/getDataFromDb';
import { setMembership } from './state/slices/userSlice';
import PricingPage from './pages/pricing/pricing-page.page';
import QuotePage from './pages/quote/quote.page';
import MyPlan from './pages/my-plan/my-plan.component';
import PrintLogins from './components/MANAGE-GAME/players/print-logins.component';


function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.userData.currentUser)
    const userProfile = useSelector(state => state.userData.userProfile)
    let membershipListener;

    useEffect(() => {
        // signInUser();
        onresize = (event) => {
            getScreenSize();
        };

    }, [])

    useEffect(() => {
        if (!userProfile) {
            if (membershipListener) membershipListener();
            return;
        };
        getMembership();
    }, [userProfile])

    function getMembership() {
        getUserMembershipFromDb({'appId': userProfile.appId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            dispatch(setMembership(data));
        }
        function handleListener(unsub) {
            membershipListener = unsub;
        }
    }

    useEffect(() => {
        if (!screenSizes) {return;}
        getScreenSize();
    }, [screenSizes])

    function getScreenSize() {
        let sizeArr = screenSizes.filter(s => window.innerWidth >= s.minWidth && window.innerWidth <= s.maxWidth);
        let size;
        if (!sizeArr || !sizeArr.length) {
            size = 'desktop'
        } else {
            size = sizeArr[0].name;
        }
        dispatch(setScreenSize(size));
    }
    
    // function signInUser() {
    //     const href = window.location.href
    //     const currentlySignedInUser = auth.currentUser;
    //     console.log(currentlySignedInUser);
        
    //     onAuthStateChanged(auth, async (user) => {
    //         if (user) {
    //             // console.log(user)
    //             console.log('app.jsx')
    //             saveUserToState(user)
    //             if (href.includes('/signin')) {
    //                 // navigate('/loading') 
    //             }
    //             // navigate('/loading')  
    //         } else {
    //             if (currentUser) {
    //                 navigate('/logout')
    //             }
    //             if (!href.includes('/signin') && !href.includes('/register') && !href.includes('/info') && !href.includes('/join')) {
    //                 navigate('/')
    //             }
    //         }
    //     });
    //     if (!currentlySignedInUser) {
    //         navigate('/logout');
    //         return;
    //     }
    // }

    // function saveUserToState(user) {
    //     if (!user) {
    //         navigate('/logout');
    //         return;
    //     }
    //     const newUser = {
    //         displayName: user.displayName,
    //         email: user.email,
    //         metadata: {
    //         createdAt: Number(user.metadata.createdAt),
    //         lastLogInAt: user.metadata.lastLogInAt
    //         },
    //         uid: user.uid,
    //         photoURL: user.photoURL,
    //         provider: user.providerData[0].providerId
    //     }
    //     dispatch(setCurrentUser({...newUser}));
    // }

    return (
        <div>
            <Routes>
                <Route path='' element={<LandingPage />}></Route>
                <Route path='/signin' element={<SignIn />}></Route>
                <Route path='/signin/:joinCode' element={<SignIn />}></Route>
                <Route path='/register' element={<Register />}></Route>
                <Route path='/register/:joinCode' element={<Register />}></Route>
                <Route path='/logout' element={<LogoutPage />}></Route>
                <Route path='/loading' element={<LoadingPage />}></Route>
                <Route path='/loading/:joinCode' element={<LoadingPage />}></Route>
                <Route path='/profile' element={<ProfilePage />}></Route>
                <Route path='/profile/:joinCode' element={<ProfilePage />}></Route>
                <Route path='/home/*' element={<HomePage />}></Route>
                <Route path='/join' element={<JoinPage />}></Route>
                <Route path='/join/:joinCode/*' element={<JoinPage />}></Route>
                <Route path='/manage/:gamePath/*' element={<ManageGamePage />}></Route>
                <Route path='/play/:gamePath/*' element={<PlayGamePage />}></Route>
                <Route path='/myplan' element={<MyPlan />}></Route>
                <Route path='/games' element={<GamesPage />}></Route>
                <Route 
                    path='printlogins/:gamePath'
                    element={<PrintLogins />}
                />
                <Route path='/admin/*' element={<AdminPage />}></Route>
                <Route path='/changelog' element={<ChangeLog />}></Route>
                <Route path='/live/:livePath' element={<LeaderboardPage />}></Route>
                <Route path='/pricing' element={<PricingPage />}></Route>
                <Route path='/quote' element={<QuotePage />}></Route>
                <Route path='/404' element={<PageNotFound />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
                <Route path="/noaccess" element={<Navigate replace to="/404" />} />
            </Routes>
        
        </div>
    );
}

export default App
