import React, { useEffect, useRef, useState } from 'react';
import './player-select-element.styles.scss';
import PlayerSelector from '@/components/SHARED/player-selector/player-selector.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import { useParams } from 'react-router-dom';

const PlayerSelectElement = ({ formData, saveData, playerList, gameElements }) => {

    const [ chosenPlayers, setChosenPlayers ] = useState([])
    const [ showRandomModal, setShowRandomModal ] = useState(false);
    const [ randomData, setRandomData ] = useState({});
    const [ alertMessage, setAlertMessage ] = useState(null);
    const { teamPath } = useParams();
    const dataRef = useRef();
    const playerRef = useRef();

    useEffect(() => {
        if (!teamPath || !playerList) return;
        const team = Object.values(playerList).filter(p => p.path === teamPath)[0];
        if (team && team.opt_members) {
            playerRef.current = team.opt_members;
            setChosenPlayers(playerRef.current);
        }
    }, [teamPath, playerList])

    useEffect(() => {
        saveData('opt_members', chosenPlayers)
    }, [chosenPlayers])

    function saveRandomData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setRandomData(dataRef.current);
        console.log(dataRef.current);
    }

    function togglePlayer(player, choice) {
        let newList = playerRef.current ? [...playerRef.current] : [];
        if (choice === 'select') {
            if (!newList.includes(player.playerId)) {
                newList.push(player.playerId)
            }
            playerRef.current = [...newList];
            setChosenPlayers(playerRef.current);
            return;
        }
        if (choice === 'deselect') {
            if (newList.includes(player.playerId)) {
                newList.splice(newList.indexOf(player.playerId), 1);
            }
            playerRef.current = [...newList];
            setChosenPlayers(playerRef.current);
            return;
        }
        
        if (newList.includes(player.playerId)) {
            newList.splice(newList.indexOf(player.playerId), 1);
        } else {
            newList.push(player.playerId);
        }
        playerRef.current = [...newList];
        setChosenPlayers(playerRef.current);
    }

    function chooseRandomPlayers() {
        if (!randomData.count) {
            setAlertMessage(`You must select at least one player to use this feature.`)
            return;
        }
        if (randomData.count > Object.values(playerList).filter(p => !p.team).length) {
            setAlertMessage(`You can't choose more players than there are in the game. Please change the count to a number less than or equal to ${Object.values(playerList).length}.`)
            return;
        }
        playerRef.current = [];
        let pickedArr = [];
        if (dataRef.current.unpicked) {
            for (let team of Object.values(playerList).filter(el => el.team)) {
                if (team.status === 'active' && team.opt_members && team.path !== teamPath) {
                    for (let memId of team.opt_members) {
                        if (!pickedArr.includes(memId)) {
                            pickedArr.push(memId);
                        }
                    }
                }
            }
            console.log(pickedArr);
        }
        if (Object.values(playerList).filter(p => !p.team).length - pickedArr.length < dataRef.current.count) {
            setAlertMessage(`Sorry! There are only ${Object.values(playerList).filter(p => !p.team).length - pickedArr.length} unpicked players left.`)
            saveRandomData('count', Object.values(playerList).filter(p => !p.team).length - pickedArr.length)
            return;
        }
        for (let i=0; i<dataRef.current.count; i++) {
            let rand = Math.floor(Math.random() * Object.values(playerList).filter(p => !p.group).length);
            let chosenPlayer = Object.values(playerList).filter(p => !p.team)[rand];
            if (dataRef.current.unpicked) {
                while (pickedArr.includes(chosenPlayer.playerId) || playerRef.current.includes(chosenPlayer.playerId)) {
                    rand = Math.floor(Math.random() * Object.values(playerList).filter(p => !p.team).length);
                    chosenPlayer = Object.values(playerList).filter(p => !p.team)[rand];
                }
            } else {
                while (playerRef.current.includes(chosenPlayer.playerId)) {
                    rand = Math.floor(Math.random() * Object.values(playerList).filter(p => !p.team).length);
                    chosenPlayer = Object.values(playerList).filter(p => !p.team)[rand];
                }
            }
            togglePlayer(chosenPlayer, 'select');
        }
        closeModal();
    }

    function closeModal() {
        dataRef.current = {}
        setRandomData(dataRef.current);
        setShowRandomModal(false);
    }

    return (
        <div className='manage-content-player-select g-list-item form-section'>
            <h3>
                Select Players
                <span className='required'>*</span>
            </h3>
            <hr />
            <button 
                className='g-button med-btn' 
                onClick={() => setShowRandomModal(true)}
            >
                Choose Random Players
                <FontAwesomeIcon icon={faRandom} />
            </button>
            <div className="g-space-0-5"></div>
            <PlayerSelector 
                playerList={playerList} 
                gameElements={gameElements} 
                chosenPlayers={chosenPlayers} 
                togglePlayer={togglePlayer} 
                type='players'
            /> 
            <div className="g-space-1"></div>

            <Modal 
                show={showRandomModal} 
                cancel={() => closeModal()} 
                cls='narrow' 
                text='Select Random Players' 
            >
                <div className='random-modal-content'>
                    <div className='field'>
                        <label>How many players should be chosen?</label>
                        <input 
                            type='number' 
                            className='short-input' 
                            min="1" 
                            max={Object.values(playerList).length} 
                            value={randomData.count ?? 1} 
                            onChange={(e) => saveRandomData('count', Number(e.target.value))}
                        />
                    </div>
                    <div className='field with-toggle'>
                        <label>Only choose players who are not already in another group?</label>
                        <ToggleInput 
                            contentKey='unpicked' 
                            checked={randomData.unpicked ?? false} 
                            saveData={saveRandomData} 
                        />
                    </div>
                    <hr />
                    <div className='buttons'>
                        <button 
                            className='g-button' 
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </button>
                        <button 
                            className='g-button primary' 
                            onClick={() => chooseRandomPlayers()}
                        >
                            Choose Random Players
                        </button>
                    </div>
                </div>
            </Modal>
            <ModalAlert 
                show={alertMessage} 
                cancel={() => setAlertMessage(null)} 
                message={alertMessage} 
            />
        </div>
    )
}

export default PlayerSelectElement