import React from 'react'
import './sidebar-admin.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faBuilding, faCity, faCode, faDice, faGamepad, faList, faMouse, faPieChart, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'

const SidebarAdmin = () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='admin-side-menu'>
            <div className='admin-menu-items'>
                <div className={`admin-menu-item ${location.pathname.includes('games') ? 'active': ''}`} id='games' onClick={() => navigate('games')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faGamepad} size='lg' />
                    Manage Games
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('orgs') ? 'active': ''}`} id='orgs' onClick={() => navigate('orgs')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faCity} size='lg' />
                    Manage Organizations
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('users') ? 'active': ''}`} id='users' onClick={() => navigate('users')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faUsers} size='lg' />
                    Manage Users
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('templategames') ? 'active': ''}`} id='templateGames' onClick={() => navigate('templategames')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faDice} size='lg' />
                    Template Games
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('userdata') ? 'active': ''}`}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faPieChart} size='lg' />
                    User Data
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('bugs') ? 'active': ''}`} id='bugs' onClick={() => navigate('bugs')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faBug} size='lg' />
                    Bug Reports
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('functions') ? 'active': ''}`} id='functions' onClick={() => navigate('functions')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faCode} size='lg' />
                    Admin Functions
                </div>
                <div className={`admin-menu-item ${location.pathname.includes('logs') ? 'active': ''}`} id='logs' onClick={() => navigate('logs')}>
                    <FontAwesomeIcon className='admin-menu-icon' icon={faMouse} size='lg' />
                    Activity Logs
                </div>
            </div>
        </div>
    )
}

export default SidebarAdmin