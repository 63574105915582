import React, { useEffect, useState } from 'react'
import './activity-list.styles.scss'
import { format } from 'date-fns'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ActivityList = ({ gameActivity, playerList, loadMoreActivity }) => {
    
    const [ moreActivity, setMoreActivity ] = useState(true);

    useEffect(() => {
        if (!gameActivity) {return;}
        // console.log(gameActivity);
        if (Object.values(gameActivity).filter(a => a.done).length > 0) {
            setMoreActivity(false);
        }
    }, [gameActivity])

    return (
        <div className='activity-list'>
            {
                (gameActivity && playerList) &&
                Object.values(gameActivity).sort((a,b) => a.ts < b.ts ? 1 : -1)
                .filter(a => a.ts > 0)
                .map(a => (
                    <div key={a.id} className='activity-item'>
                        <ImageContainer src={a.icon} alt='activity icon' className='activity-icon' />
                        <div className='activity-text'>
                            <b>{a.title}</b>: {a.event} by <b>{a.userName ? a.userName : (playerList[a.playerId] && playerList[a.playerId].displayName) ? playerList[a.playerId].displayName : ''}</b> on {format(new Date(a.ts), 'Pp')}
                        </div>
                    </div>
                ))
            }
            {/* {
                (moreActivity) &&
                <div className='more-button'>
                    <button className='g-button small-btn' onClick={() => loadMoreActivity()}>Load more...</button>
                </div>
            } */}
        </div>
    )
}

export default ActivityList