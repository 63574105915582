import React, { useEffect, useRef, useState } from 'react'
import './create-edit-org.styles.scss';

import { useNavigate } from 'react-router-dom';
import flatpickr from 'flatpickr';
import states from '@/utils/states.json'
import UIkit from 'uikit';
import { useDispatch, useSelector } from 'react-redux';
import { RandomString } from '@/utils/randomString';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { setOrgList } from '@/state/slices/contentSlice';
import BackButton from '@/components/SHARED/back-button/back-button.component';

const CreateEditOrg = ({ org }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const orgs = useSelector(state => state.contentData.orgList);
    const [ formData, setFormData ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ flatpickrInstance, setFlatpickrInstance ] = useState(null);
    const [ flatpickrInstance1, setFlatpickrInstance2 ] = useState(null);
    const dataRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (org) {
            dataRef.current = org
            setFormData(dataRef.current);
        }
    }, [org])

    useEffect(() => {
        const startDateElement = document.getElementById(`joinDate`);
		let fp1 = flatpickr(startDateElement, 
			{
				altInput: true,
	            altFormat: "F j, Y",
	            dateFormat: "Y-m-d",
				onChange: (dates) => {saveData('joinDate', new Date(dates[0]).getTime())}
			}
		);
		setFlatpickrInstance(fp1);
        if (org) {
            fp1.setDate(new Date(org.joinDate));
            fp2.setDate(new Date(org.expireDate));
        }
        const endDateElement = document.getElementById(`expireDate`);
		let fp2 = flatpickr(endDateElement, 
			{
				altInput: true,
	            altFormat: "F j, Y",
	            dateFormat: "Y-m-d",
				onChange: (dates) => {saveData('expireDate', new Date(dates[0]).getTime())}
			}
		);
		setFlatpickrInstance2(fp2);
    }, [])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        const req = ['name','joinDate','expireDate','address','city','state','zip','contactName','contactEmail']
        for (let r of req) {
            if (!formData[r]) {
                UIkit.modal.alert('Please complete all of the required parts of the form before moving on.')
                return;
            }
        }
        setIsLoading(true);
        const newObj = {...formData};
        const now = new Date().getTime();
        if (formData.orgId) {
            newObj.orgId = formData.orgId;
            newObj[`edited-${now}`] = {
                'ts': now,
                'appId': profile.appId                
            }
        } else {
            newObj.creatorAppId= profile.appId
            newObj.orgId = RandomString(20);
            newObj.ts = now;
            newObj.stat_userCount = 1;
            newObj.orgType = 'pro';
        }
        newObj.status = 'active';
        const res = await UseCloudFunction('saveOrg', {'obj': newObj, 'ts': now});
        console.log(res);
        dispatch(setOrgList({...orgs, ...{[res.newOrg.orgId]: res.newOrg}}))
        setFormData({})
        dataRef.current = null;
        navigate(-1)
    }

    return (
        <div className='create-org-container'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-30"></div>
                <h4>Create a New Organization</h4><hr />
                <div className='field'>
                    <label>Organization Name</label>
                    <input type='text' value={formData.name ?? ''} onChange={(e) => saveData('name', e.target.value)} />
                </div>
                <div className='g-double-col'>
                    <div className='field'>
                        <label>Join Date</label>
                        <input className="flatpickr flatpickr-input" type="text" id='joinDate' placeholder="Pick a date..." />
                    </div>
                    <div className='field'>
                        <label>Expiration Date</label>
                        <input className="flatpickr flatpickr-input" type="text" id='expireDate' placeholder="Pick a date..." />
                    </div>
                </div>
                <div className='field'>
                    <label>Address</label>
                    <input type='text' value={formData.address ?? ''} onChange={(e) => saveData('address', e.target.value)} />
                </div>
                <div className='field'>
                    <label>Address Line2</label>
                    <input type='text' value={formData.address2 ?? ''} onChange={(e) => saveData('address2', e.target.value)} />
                </div>
                <div className='field'>
                    <label>City</label>
                    <input type='text' value={formData.city ?? ''} onChange={(e) => saveData('city', e.target.value)} />
                </div>
                <div className='g-double-col'>
                    <div className='field'>
                        <label>State</label>
                        <select value={formData.state ?? ''} onChange={(e) => saveData('state', e.target.value)} >
                            <option value=''>Choose...</option>
                            {
                                states &&
                                Object.keys(states).map(st => (
                                    <option key={st} value={st}>{states[st]}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='field'>
                        <label>Zip Code</label>
                        <input type='text' value={formData.zip ?? ''} onChange={(e) => saveData('zip', e.target.value)} />
                    </div>
                    
                </div>
                <div className='field'>
                    <label>Primary Contact Name</label>
                    <input type='text' value={formData.contactName ?? ''} onChange={(e) => saveData('contactName', e.target.value)} />
                </div>
                <div className='g-double-col'>
                    <div className='field'>
                        <label>Primary Contact Email</label>
                        <input type='text' value={formData.contactEmail ?? ''} onChange={(e) => saveData('contactEmail', e.target.value)} />
                    </div>
                    <div className='field'>
                        <label>Primary Contact Phone</label>
                        <input type='text' value={formData.contactPhone ?? ''} onChange={(e) => saveData('contactPhone', e.target.value)} />
                    </div>
                </div>
                <div className="g-space-30"></div>
                <div className='submit-buttons'>
                    <button className='g-button'>Cancel</button>
                    {
                        (!isLoading) 
                        ?
                        <button className='g-button primary submit-button' onClick={() => handleSubmit()}>Save Organization</button>
                        :
                        <button className='g-button submit-button disabled'><div data-uk-spinner='ratio: .5'></div></button>
                    }
                </div>
            </div>
        </div>
    )
}


export default CreateEditOrg    