import React, { useEffect, useRef, useState } from 'react';
import './admin-manage-games-landing.styles.scss';
import { getGamesFromDb } from '@/utils/getAdminData';

const AdminManageGamesLanding = () => {

    const [ gamesToDisplay, setGamesToDisplay ] = useState(null);
    const [ count, setCount ] = useState(20);
    const gamesRef = useRef();

    useEffect(() => {
        getGames();
    }, [])

    async function getGames() {
        const games = await getGamesFromDb()
    }

    return (
        <div className='manage-games-landing'>
            <div className='manage-games-content'>
                <h3>Manage Games</h3>
                <hr />
            </div>
            
        </div>
    )
}

export default AdminManageGamesLanding