import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './org-list.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { getOrgsFromDb } from '@/utils/getAdminData';
import { useNavigate } from 'react-router-dom';
import org_placeholder from '@/assets/placeholder-icon-org.jpg'

const OrgList = () => {

    const [ orgList, setOrgList ] = useState();
    let orgListener; 
    const orgListRef = useRef();
    const navigate = useNavigate();
    
    useEffect(() => {
        
        return () => {
            if (orgListener) {
                console.log('detaching org listener');
                orgListener();
            }
        }
    }, [])

    useLayoutEffect(() => {
        getOrgs();
    }, [])

    async function getOrgs() {
        const obj = {
            'callback': function (e) {orgsCallback(e)},
            'lastOrg': null,
            'receiveListener': function (e) {receiveListener(e)},
        }
        getOrgsFromDb(obj);
    } 

    function receiveListener(unsubscribe) {
        orgListener = unsubscribe;
    }

    async function orgsCallback(newOrg) {
        console.log(newOrg);
        orgListRef.current = {...orgListRef.current, ...{[newOrg.orgId]: newOrg}}
        setOrgList(orgListRef.current);
    }

    return (
        <div className='org-list'>
            {
                (orgList) &&
                <table className='g-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Org Name</th>
                            <th>Primary Acct</th>
                            <th>Members</th>
                            <th>Actions</th>

                        </tr>                    
                    </thead>
                    <tbody>
                        {
                            Object.values(orgList).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                            .map(o => (
                                <tr key={o.orgId}>
                                    <td><img className='org-icon' src={(o.icon && o.icon !== 'none') ? o.icon : org_placeholder} /></td>
                                    <td>{o.name}</td>
                                    <td><a href={`mailto:${o.contactEmail}`}>{o.contactName}</a></td>
                                    <td>{o.memberCount ?? 0}</td>
                                    <td>
                                        <FontAwesomeIcon icon={faPen} className='clickable' onClick={() => navigate(o.orgId)}/>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </div>
    )
}

export default OrgList