import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './admin.styles.scss';
import HeadBarAdmin from '@/components/ADMIN/head-bar-admin/head-bar-admin.component';
import SidebarAdminContainer from '@/components/ADMIN/sidebar-admin/sidebar-admin-container.component';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import OrgListContainer from '@/components/ADMIN/manage-orgs/org-list-container.component';
import UserListContainer from '@/components/ADMIN/manage-users/user-list-container.component';
import CreateOrg from '@/components/ADMIN/manage-orgs/create-org/create-edit-org.component';
import { useDispatch, useSelector } from 'react-redux';
import { setOrgList } from '@/state/slices/contentSlice';
import OrgView from '@/components/ADMIN/manage-orgs/org-view.component';
import UserView from '@/components/ADMIN/manage-users/user-view.component';
import AdminActivityLogs from '@/components/ADMIN/activity-logs/activity-logs.component';
import AdminBugReports from '@/components/ADMIN/bug-reports/bug-reports.component';
import AdminFunctions from '@/components/ADMIN/functions/functions.components';
import { UseCloudFunction } from '@/utils/firebase.utils';
import Loader from '@/components/loader/loader.comp';
import TemplateGamesContainer from '@/components/ADMIN/template-games/template-games-container.component';
import ManageGamePage from '../manage-game/manage-game.page';
import AdminManageGamesLanding from '@/components/ADMIN/manage-games/admin-manage-games-landing.component';

const AdminPage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ admin, setAdmin ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkForAccess() {
            const adminTF = await UseCloudFunction('checkAdmin', {'email': userProfile.email, 'appId': userProfile.appId})
            setAdmin(adminTF.success)
            if (!adminTF) {
                navigate('/home');
            }
        }
        checkForAccess();
    }, [])

    return (
        <>
        { (admin)
        ?
        <div className='admin-page'>
            <HeadBarAdmin />
            <div className='admin-layout'>
                <SidebarAdminContainer />
                <div className='admin-right-content'>
                    <Routes>
                        <Route path='' element={'Admin content'}></Route>
                        <Route path='games/*' element={<AdminManageGamesLanding />}></Route>
                        <Route path='orgs' element={<OrgListContainer />}></Route>
                        <Route path='orgs/create' element={<CreateOrg />}></Route>
                        <Route path='orgs/:orgId' element={<OrgView />}></Route>
                        <Route path='users' element={<UserListContainer />}></Route>
                        <Route path='users/:appId' element={<UserView />}></Route>
                        <Route path='templategames/*' element={<TemplateGamesContainer />}></Route>
                        <Route path='logs' element={<AdminActivityLogs />}></Route>
                        <Route path='bugs' element={<AdminBugReports />}></Route>
                        <Route path='functions' element={<AdminFunctions />}></Route>
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Routes>
                </div>
            </div>
        </div>
        :
        <div className='admin-page'>
            <div className='loader-container'>
                <Loader />
            </div>
        </div>
        }
        </>
    )
}

export default AdminPage