import React, { useEffect } from 'react'
import './manage-user-dropdown.styles.scss'

import { Link, useLocation } from 'react-router-dom'
import { version } from '@/changelog-version'
import { useSelector } from 'react-redux'

const ManageUserDropdown = ({ show }) => {
    
    const membership = useSelector(state => state.userData.membership);
    const location = useLocation();

    return (
        <div className={show ? 'active manage-dropdown-container' : 'manage-dropdown-container'}>
            <div className="dropdown-menu">
                <Link to='/home'>My Games</Link>
                <Link to='/profile'>My Profile</Link>
                <Link to='/myplan'>My Plan</Link>
                <Link to='/changelog' target='_blank'>Change Log</Link>
                <Link to='/logout'>Sign Out</Link>
                <p className='meta'>{version}</p>
            </div>
        </div>
    )
}

export default ManageUserDropdown