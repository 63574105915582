import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './user-list-container.styles.scss'
import { getAllUsersFromDb } from '@/utils/getAdminData';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const UserListContainer = () => {

    const [ userList, setUserList ] = useState();
    let userListener; 
    const userListRef = useRef();
    const navigate = useNavigate();
    
    useEffect(() => {
        
        return () => {
            if (userListener) {
                console.log('detaching user listener');
                userListener();
            }
        }
    }, [])

    useLayoutEffect(() => {
        getUsers();
    }, [])

    async function getUsers() {
        const obj = {
            'callback': function (e) {usersCallback(e)},
            'lastUser': null,
            'receiveListener': function (e) {receiveListener(e)},
        }
        getAllUsersFromDb(obj);
    } 

    function receiveListener(unsubscribe) {
        userListener = unsubscribe;
    }

    async function usersCallback(newUser) {
        // console.log(newUser);
        userListRef.current = {...userListRef.current, ...{[newUser.appId]: newUser}}
        setUserList(userListRef.current);
    }

    return (
        <div className='user-list'>
            <div className='section-title with-icon'>
                <span>Manage Users</span>
                <FontAwesomeIcon className='icon' title="Create New Organization" icon={faPlusCircle} onClick={() => navigate('create')} />
            </div>
            <hr />
             {
                (userList) &&
                <table className='g-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Organization(s)</th>
                            <th>Last Login</th>
                            <th>App ID</th>
                            <th>Actions</th>

                        </tr>                    
                    </thead>
                    <tbody>
                        {
                            Object.values(userList).sort((a,b) => a.lName.toLowerCase() > b.fName.toLowerCase() ? 1 : -1)
                            .map(u => (
                                <tr key={u.appId}>
                                    <td><img src={u.icon} className='user-icon' /></td>
                                    <td>{u.fName} {u.lName}</td>
                                    <td><a href={`mailto:${u.email}`}>{u.email}</a></td>
                                    <td>{
                                        u.regs.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                        .map(reg => (
                                            <div key={reg.orgId}><span><a href={`/admin/orgs/${reg.orgId}`}>{reg.name} ({reg.role})</a></span></div>
                                        ))    
                                    }</td>
                                    <td>{format(new Date(), 'Pp')}</td>
                                    <td>{u.appId}</td>
                                    <td>
                                        <FontAwesomeIcon icon={faPen} className='clickable' onClick={() => navigate(u.appId)}/>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </div>
    )
}

export default UserListContainer