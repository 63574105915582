import React, { useEffect, useRef, useState } from 'react'
import './teams-form.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ManageContentInformation from '../create-elements/blocks/information.component';
import ManageContentIconCropped from '../create-elements/blocks/icon.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { RandomString } from '@/utils/randomString';
import PlayerSelectElement from '../create-elements/blocks/player-select-element.component';

const TeamForm = ({ gameData, gameElements, playerList }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({})
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ existingDescription, setExistingDescription ] = useState(null);
    const [ clearEditor, setClearEditor ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const { teamPath } = useParams();
    const dataRef = useRef();
    const approveRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();

    useEffect(() => {
        if (!teamPath || !playerList || Object.values(playerList).length === 0) {return;}
        console.log(playerList);
        dataRef.current = Object.values(playerList).filter(p => p.path === teamPath)[0];
        setFormData(dataRef.current);
        if (dataRef.current.desc) {
            setExistingDescription(dataRef.current.desc);
        }
    }, [teamPath, playerList])

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }
    
    function cancelCreate() {
        setConfirmMessage('Are you sure you want to cancel? All of your data will be lost.');
        approveRef.current = () => {
            dataRef.current = {};
            setFormData(dataRef.current);
            navigate(-1)
        }
    }

    async function handleSubmit() {
        let tempData = {...dataRef.current}
        const reqs = ['name', 'icon']
        for (let r of reqs) {
            if (!tempData[r]) {
                setAlertMessage('Please include a name and an icon for this Team before saving.');
                return;
            }
        }
        setConfirmMessage('Are you sure you are ready to save this Team? All of these settings can be changed later.')
        approveRef.current = () => {
            finishApproval()
        }
    }

    async function finishApproval() {
        setLoading(true);
        let tempData = {...dataRef.current}
        if (tempData.icon.upload) {
			tempData.icon.croppie.result({
				type:'blob',
				size: {'width': 352, 'height': 352},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(tempData.icon, blob)
				return;
			})
		} else if (tempData.icon.url) {
			saveTeam(tempData.icon.url)
			return;
		} else if (tempData.icon) {
            saveTeam(tempData.icon)
            return; 
        } else {
			setAlertMessage('Something went wrong with the icon. Please try using a different image.');
            setLoading(false);
            return;
		}
    }

    function uploadImage(icon, blob) {		
		const now = new Date().getTime();
		const iconRef = ref(storage, `${gameData.gameId}/teamIcons/${now}-${icon.name}`)
		const metadata = {
			contentType: icon.type,
            customMetadata: {
                appId: userProfile.appId,
                email: userProfile.email
            }
		};
		uploadBytes(iconRef, blob, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				saveTeam(url)
				return;
			})
		})
	}

    async function saveTeam(iconUrl) {
        let tempData = {...dataRef.current}
        console.log(tempData);
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
        if (!tempData.playerId) {
            tempData.appId = RandomString(20) + "_team";
            tempData.gameId = gameData.gameId;
            tempData.joined = now;
            tempData.points = 0;
            tempData.playerId = gameData.gameId + "_" + tempData.appId;
            tempData.status = 'active';
            tempData.team = true;
            tempData.path = RandomString(18, 'abcdefghijkmnopqrstuvwxyz-_023456789');
        } 
        tempData.displayName = tempData.name;
        tempData.fName = tempData.name;
        tempData.ts = now;
        tempData.icon = iconUrl;
        console.log(tempData);
        const res = await UseCloudFunction(
            'savePlayerData', 
            {
                'appId': userProfile.appId, 
                'dataList': {[tempData.playerId]: tempData}, 
                'gameId': gameData.gameId
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
            setLoading(false);
            return;
        }
        setAlertMessage('Team saved successfully.');
        dataRef.current = {};
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='create-team-form'>
            <ManageContentInformation 
                type='team' 
                saveData={saveData} 
                formData={formData} 
                existingDescription={existingDescription} 
                clearEditor={clearEditor} 
                setClearEditor={setClearEditor}
                textEditorLabel='Team Description (optional)'
            />
            <div className='g-space-2'></div>
            <ManageContentIconCropped
                type='team' 
                saveData={saveData} 
                formData={formData} 
            />        
            <div className='g-space-2'></div>
            <PlayerSelectElement 
                formData={formData} 
                saveData={saveData}
                playerList={playerList} 
                gameElements={gameElements}  
            />
            <div className='g-space-1'></div>
            <span className='required'>* = Required</span>
            <div className='buttons'>
                {
                    (loading) 
                    ?
                    <>
                        <button type='button' className='g-button'>Cancel</button>
                        <button type='button' className='g-button'><ThreeDotsLoader /></button>
                    </>
                    :
                    <>
                        <button type='button' className='g-button' onClick={() => cancelCreate()}>Cancel</button>
                        <button type='button' className='g-button submit-button' onClick={() => handleSubmit()}>Save Team</button>
                    </>
                }
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
        </div>
    )
}

export default TeamForm