import React, { useState, useEffect } from 'react'

import upload from '@/assets/icons/upload-grad.png';
import atIcon from '@/assets/icons/at-grad.png';
import unlocked from '@/assets/icons/unlocked-grad.png';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@/components/modal/modal.component';
import ToggleInput from '@/components/SHARED/toggle-input/toggle-input.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import user_icon from '@/assets/icons/user4.png';
import at_icon from '@/assets/icons/at-grad.png';
import upload_icon from '@/assets/icons/upload-grad.png';

const CreateEditForm4 = ({ edit, formData, saveData, verifyAndMoveOn, cancel, validJoinCode }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [ req ] = useState([])
    const [ showLimitModal, setShowLimitModal ] = useState(false);

    useEffect(() => {
        if (!formData.gameName && !edit) {
            navigate('/home/creategame');
        }
    }, [formData])

    function saveLimitList() {
        let list = document.getElementById('limit-list-input').value;
        list = list.replaceAll(" ", "").split(',');
        console.log(list);
        saveData('enroll_limitList', list);
        setShowLimitModal(false);
    }

    function toggle(key, value) {
        saveData(key, value);
        if (key === 'enroll_email' && !value) {
            saveData('enroll_restricted', false);
            document.getElementById('limit-list-input').value = null;
            saveData('enroll_limitList', null);
        }
    }

    return (
        <div className='new-game-form' id='new-game-form'>
            <div>
            <h3>How to Roster Players</h3>
            <div className="g-space-1"></div>
            <div className='field with-toggle'>
                <label className='with-image'>
                    <img 
                        src={upload_icon} 
                        alt='user icon' 
                        className='label-image' 
                    />
                    Game managers can create players individually or by uploading a list. Players sign in using the passwords you provide them.
                </label>
                <ToggleInput contentKey='enroll_upload' checked={formData.enroll_upload ?? false} saveData={toggle} />
            </div>
            <div className="g-space-1"></div>
            <div className='field with-toggle'>
                <label className='with-image'>
                    <img 
                        src={at_icon} 
                        alt='@ icon' 
                        className='label-image' 
                    />
                    Players can join with their Google, Microsoft or Email accounts using the join code or enroll link.
                </label>
                <ToggleInput contentKey='enroll_email' checked={formData.enroll_email ?? false} saveData={toggle} />
            </div>
            <div className="g-space-1"></div>
            <div className={`field with-toggle ${!formData.enroll_email ? 'disabled' : ''}`}>
                <label className='with-image'>
                    <img 
                        src={user_icon} 
                        alt='user icon' 
                        className='label-image' 
                    />
                    Only allow users with the following email addresses or domains to join the game.
                </label>
                <ToggleInput contentKey='enroll_restricted' checked={formData.enroll_restricted ?? false} saveData={toggle} />
            </div>
            <div className="g-space-1"></div>
            <div className={`field ${!formData.enroll_restricted ? 'disabled' : ''}`}>
                <label className='no-flex'>
                    <h3>Allowed Emails/Domains</h3>
                    <p>
                        Include any approved emails or domains in the box below. <b>Separate them with commas.</b> You can always change this list after it's been created by going to the Game Settings.
                    </p>
                </label>
                <textarea 
                    id='limit-list-input' 
                    rows="3"
                    placeholder='ex: joe@email.com, @gamably.com'
                    defaultValue={formData.enroll_limitList ?? ''}
                    onChange={() => saveLimitList()}
                ></textarea>
            </div>
            
                {/* <h4>How will players join?</h4><hr /> */}
                {/* <div className={`g-card game-type-card small-image ${formData.popMethod === 'input' ? 'active': ''}`} onClick={() => choosePopMethod('input')}>
                    <div className='image-grid'>
                        <img src={upload} />
                        
                    </div>
                    <div className='explanation-grid'>
                        <h4>Input or Upload a List of Inhabitants</h4>
                        <p>You can add your own list of players either one at a time, or by uploading a .csv file. A specific username and password will then be created for them, and you can then print out their created login information to distribute. Players cannot join this game using a connected Google or Microsoft account.</p>
                    </div>
                </div>
                <div className="g-space-20"></div>
                <div className={`g-card game-type-card small-image ${formData.popMethod === 'limit' ? 'active': ''}`} onClick={() => choosePopMethod('limit')}>
                    <div className='image-grid'>
                        <img src={atIcon} />
                        
                    </div>
                    <div className='explanation-grid'>
                        <h4>Limit Players With a List of Accepted Emails or Domains</h4>
                        <p>This method allows you to enter a list of emails or domains that you want to allow into your game. You can send out the Gamably.com link and the created Join Code, and players will then join your game after creating an account. If their email or domain is not on the list, they will be denied entry.</p>
                    </div>
                </div>
                <div className="g-space-20"></div>
                <div className={`g-card game-type-card small-image ${formData.popMethod === 'unlock' ? 'active': ''}`} onClick={() => choosePopMethod('unlock')}>
                    <div className='image-grid'>
                        <img src={unlocked} />
                        
                    </div>
                    <div className='explanation-grid'>
                        <h4>Allow Anyone to Join</h4>
                        <p>This will open the doors of your game to anyone with the Join Code and a valid account. You can send out the Gamably.com link and the created Join Code, and players will then join your game after creating an account.</p>
                    </div>
                </div> */}
            </div>
            <div className="g-space-20"></div>
            {   
                (location.pathname.includes('/creategame')) &&
                <>
                <div className='buttons'>
                    <button className='g-button' onClick={() => navigate(-1)}>Back</button>
                    <button className='g-button' onClick={() => cancel()}>Cancel</button>
                    <button className='g-button primary' onClick={() => verifyAndMoveOn(req, 'review')}>Review Game</button>
                </div>
                </>
            }
        </div>
    )
}

export default CreateEditForm4;