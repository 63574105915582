import React, { useEffect, useLayoutEffect } from 'react';
import './org-settings.styles.scss';
import OrgInfo from './settings-components/org-info.component';
import OrgIcon from './settings-components/org-icon.component';
import { useLocation } from 'react-router-dom';
import OrgTerms from './settings-components/org-terms.component';

const OrgSettings = ({ orgData, saveOrgData }) => {

    const location = useLocation();

    return (
        <div className='org-settings-container'>
            <div className='org-settings'>
                <div className='g-card'>
                    <OrgInfo orgData={orgData} saveOrgData={saveOrgData} />
                </div>
                <div className="g-space-40"></div>
                <div className='g-card'>
                    <OrgIcon orgData={orgData} saveOrgData={saveOrgData} />
                </div>
                <div className="g-space-40"></div>
                <div className='g-card'>
                    <OrgTerms orgData={orgData} saveOrgData={saveOrgData} />
                </div>
                <div className="g-space-40"></div>
            </div>
        </div>
    )
}

export default OrgSettings;