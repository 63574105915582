import React, { useState, useEffect } from 'react'
import './sidebar-left.styles.scss'

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faBuilding, faChartArea, faChartLine, faDice, faGear, faHome, faHouse, faList, faMountainCity, faPenSquare, faPenToSquare, faSliders, faUsers } from '@fortawesome/free-solid-svg-icons';
import { mediaQueries } from '@/utils/responsiveQueries';
import { useMediaQuery } from 'react-responsive';
import BugModal from '../bug-modal/bug-modal.component';

export function switchOrgMenu(e) {
    const menuItems = document.getElementsByClassName('menu-item')
    for (let item of menuItems) {
        item.classList.remove('active')
    }
    if (document.getElementById(e)) {
        document.getElementById(e).classList.add('active');
    }
}

const SidebarLeft = ({ toggleMenu, orgId, setShowBugModal }) => {

    const [ viewDrop, setViewDrop ] = useState(false);
    const [ showServices, setShowServices ] = useState(false);
    const [ showSearchModal, setShowSearchModal ] = useState(false);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    const navigate = useNavigate();


    return (
        <div>
            <div className={`sidebar-container ${isMobileDevice ? 'mobile' : ''}`}>

                
                <div className="g-height-20"></div>
                {/* <div className="button-container">
                    
                    <button className={`start-button ${showSearchModal ? 'clicked' : ''}`} onClick={() => setShowSearchModal(true)}>
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="button-text">Get Started</span>
                    </button>
                </div> */}
                <div className={`drop-container ${viewDrop ? 'active' : ''}`}>
                    <div className="drop-menu">
                        
                        {/* <div className="drop-menu-item" id="newFolder" onClick={() => setShowSearchModal(true)}> 
                            <img src={require('assets/icons/play.png')} className="drop-menu-item-icon" />
                            Add To WatchList
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newsuggestion')}> 
                            <img src={require('assets/icons/suggestion.png')} className="drop-menu-item-icon" />
                            Recommend
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newchat')}> 
                            <img src={require('assets/icons/chat.png')} className="drop-menu-item-icon" />
                            New Chat
                        </div> */}
                        
                        
                    </div>
                </div>
                <div className="g-height-20"></div>
                <div className="menu-container">
                    {/* <div className='menu-item' id='home-menu-home' onClick={() => navigate(`/home`)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faHouse} size='lg' />
                        Home
                    </div> */}
                    <div className="menu-item" id='home-menu-managing' onClick={() => navigate('/home/managing')}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faSliders} size='lg' />
                        Games I Manage
                    </div>
                    <div className='menu-item' id='home-menu-enrolled' onClick={() => navigate(`/home/enrolled`)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faDice} size='lg' />
                        Games I'm Playing
                    </div>
                    <div className="menu-item" id='home-menu-orgs' onClick={() => navigate('/home/myorgs')}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faMountainCity} size='lg' />
                        My Orgs
                    </div>
                    <div className='menu-item' id='home-menu-settings' onClick={() => navigate('/home/settings')}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faGear} size='lg' />
                        Settings
                    </div>
                    <div className="menu-item" id='home-menu-bugs' onClick={() => setShowBugModal(true)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faBug} size='lg' />
                        Report a Bug
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default SidebarLeft;