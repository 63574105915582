import React, { useEffect, useState } from 'react'
import './category-filter-modal.styles.scss';
import Modal from '@/components/modal/modal.component';

const CategoryFilterModal = ({ show, cancel, chooseCategory, categoryList, selectedCategories, text, color }) => {

    return (
        <div className='category-filter-modal'> 
            <Modal show={show} cancel={cancel} closeButton={true} cls='narrow' text={text ?? 'Filter by Category'}>
                <div className='modal-children'> 
                    <div className='filter-list'>
                        {
                            (categoryList && categoryList.length > 0) 
                            ?
                            categoryList.sort((a,b) => {
                                if (a.name && a.name.toLowerCase() > b.name.toLowerCase()) {
                                    return 1;
                                } else if (a.name && a.name.toLowerCase() < b.name.toLowerCase()) {
                                    return 1;
                                } else if (a.toLowerCase() > b.toLowerCase()) {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            })
                            .map(c => (
                                <div key={c.id ?? c.playerId ?? c} className={`filter-button-div`}>
                                    <button 
                                        className='g-button med-btn' style={{
                                            'backgroundColor': selectedCategories && selectedCategories.includes(c.id ?? c.playerId ?? c) ? color ?? 'var(--primary)' : '',
                                            'color': selectedCategories && selectedCategories.includes(c.id ?? c.playerId ?? c) ? '#fff' : ''
                                        }} 
                                        onClick={() => chooseCategory(c.id ?? c.playerId ?? c)}
                                    >{c.name ?? c}</button>
                                </div>
                            ))
                            :
                            <p>Nothing to filter yet...</p>
                        }
                    </div>
                    <div className='buttons'>
                        <button className='g-button med-btn' onClick={cancel}>OK</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default CategoryFilterModal